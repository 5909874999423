import { useState, useEffect } from "react";
import { Box, Text, Img, Flex, useMediaQuery } from "@chakra-ui/react";
import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import { AddressZero } from '@ethersproject/constants';
import { INTERVAL_MS } from "../../constants/general";
import { useTrait } from "../../hooks";
import { formatBalance } from "../../utils/web3";
import DataStore from "../../utils/DataStore";
import { WETH } from '../../constants/tokens';
import { viewWeb3 } from "../../utils/CommitmentUtils";
import Navbar from "../../components/Navbar/Navbar";
import GenomesWelcome from "../../assets/homemask.jpg";
import FirstRow from "../../components/Cards/FirstRow";
import SecondRow from "../../components/Cards/SecondRow";
import Folder from "../../assets/folder.png";
import Discord from "../../assets/discord2.png";
import Dao from "../../assets/dao.png";
import Liquidity from "../../assets/liquidity.png";
import Blog from "../../assets/blog.png";
import ThirdRow from "../../components/Cards/ThirdRow";
import APY from "../../assets/apy.png";
import GENE from "../../assets/Gene.png";
import GNOME from "../../assets/gnome.png";
import FourthRow from "../../components/Cards/FourthRow";
import GENEGNOMEART from "../../assets/art.png";
import COPYRIGHT from "../../assets/copyright.png";
import MobileWelcome from "../../assets/mobilewelcome.jpg";
import axios from "axios";

const Home = () => {
  const accountData = useTrait(null);
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [genePrice, setGenePrice] = useState('0.00');
  const [gnomePrice, setGnomePrice] = useState('0.00');
  // const [prices, setPrices] = useState<{
  //   gene: { usd: number };
  //   gnome: { usd: number };
  // }>({
  //   gene: { usd: 0 },
  //   gnome: { usd: 0 },
  // });
  // const [count, setCount] = useState(0);
  // const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  // const [isNotMobile] = useMediaQuery("(min-width: 991px)");

  // useEffect(() => {
  //   const timer = window.setInterval(() => {
  //     setCount((prev) => prev + 1);
  //   }, 50000);

  //   return () => {
  //     window.clearInterval(timer);
  //   };
  // }, []);

  // useEffect(() => {
  //   const fetchPrice = async () => {
  //     try {
  //       const current = await axios.get(
  //         "https://api.coingecko.com/api/v3/simple/price?ids=gnome%2Cgene&vs_currencies=usd"
  //       );
  //       setPrices(current.data);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchPrice();
  // }, [count]);

  async function fetchPrice() {
    // if (!account) return;
    const accData = await loadData();
    if (!accData) return;
    try {
      // const price4eth = await getCurrentCoinValue();

      const price4eth = ethers.utils.formatUnits(
        accData['chainlink_WETH']['latestRoundData'][1].toString(),
        8
      );
      let address01: string = accData['LP_GENE']['token0'][0];
      let address02: string = accData['LP_GENE']['token1'][0];
      let address = address02;
      let reserves = accData['LP_GENE']['reserves'];
      let amt01 = formatBalance(reserves[0]);
      let amt02 = formatBalance(reserves[1]);
      let amt = amt01;
      if (WETH.address.toLowerCase() !== address01.toLowerCase()) {
        amt = amt01;
        amt01 = amt02;
        amt02 = amt;
        address = address01;
      }
      setGenePrice(
        new BigNumber(price4eth)
          .multipliedBy(new BigNumber(amt01))
          .dividedBy(new BigNumber(amt02))
          .toFixed(3)
      );

      address01 = accData['LP_GNOME']['token0'][0];
      address02 = accData['LP_GNOME']['token1'][0];
      address = address02;
      reserves = accData['LP_GNOME']['reserves'];
      amt01 = formatBalance(reserves[0]);
      amt02 = formatBalance(reserves[1]);
      if (WETH.address.toLowerCase() !== address01.toLowerCase()) {
        amt = amt01;
        amt01 = amt02;
        amt02 = amt;
      }
      setGnomePrice(
        new BigNumber(price4eth)
          .multipliedBy(new BigNumber(amt01))
          .dividedBy(new BigNumber(amt02))
          .toFixed(3)
      );
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let isMounted = true;
    fetchPrice();
    const interval = setInterval(() => {
      if (isMounted) {
        fetchPrice();
      }
    }, INTERVAL_MS);

    return () => {
      clearInterval(interval);
      isMounted = false;
    }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  async function loadData() {
    let data: any;
    let accData: any;
    data = new DataStore({
      networkId: 1,
      zoom: true,
      zoom_debug: true,
      debug: true,
      provider: viewWeb3,
    });

    await data.init();
    accData = await data.loadData(AddressZero);
    accountData.set(accData);
    return accData;
  }

  return (
    <Box>
      <Navbar />
      <Text
        color='#35067C'
        fontFamily='roman'
        fontSize={startTab && endTab ? "30px" : isMobile ? "30px" : "50px"}
        pt={5}
        textAlign='center'
        mx={isMobile ? 5 : ""}
      >
        How to get involved in{" "}
        <span style={{ fontFamily: "helvetica-bold", fontWeight: "bolder" }}>
          GenomesDAO
        </span>
      </Text>
      <Flex>
        <Img w='100%' src={isMobile ? MobileWelcome : GenomesWelcome} />
      </Flex>
      {startTab && endTab ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={20}
          mx={startTab && endTab ? 10 : 60}
        >
          <FirstRow cardText='GenomesDAO Docs' Image={Folder} />

          <FirstRow
            cardText='Join the community'
            subText='Official Discord'
            Image={Discord}
          />
        </Flex>
      ) : isMobile ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
        >
          <FirstRow cardText='GenomesDAO Docs' Image={Folder} />

          {/* <FirstRow
          cardText='Join the community'
          subText='Official Discord'
          Image={Discord}
        /> */}
        </Flex>
      ) : (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={20}
          mx={startTab && endTab ? 10 : 60}
        >
          <FirstRow cardText='GenomesDAO Docs' Image={Folder} />

          <FirstRow
            cardText='Join the community'
            subText='Official Discord'
            Image={Discord}
          />
          <FirstRow cardText='DAO Snapshot Voting' Image={Dao} />
        </Flex>
      )}
      {startTab && endTab ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : 60}
        >
          <FirstRow cardText='DAO Snapshot Voting' Image={Dao} />
          <SecondRow
            cardText='Liquidity Provider Incentives'
            Image={Liquidity}
          />
        </Flex>
      ) : isMobile ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
        >
          <FirstRow
            cardText='Join the community'
            subText='Official Discord'
            Image={Discord}
          />
        </Flex>
      ) : (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : 60}
        >
          <SecondRow
            cardText='Liquidity Provider Incentives'
            Image={Liquidity}
          />
          <SecondRow cardText='GenomesDAO Blog' Image={Blog} />
        </Flex>
      )}
      {startTab && endTab ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : 60}
        >
          <SecondRow cardText='GenomesDAO Blog' Image={Blog} />
          <ThirdRow Image={APY} />
        </Flex>
      ) : isMobile ? (
        <Flex
          flex='1'
          justifyContent='space-between'
          mt={10}
          mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
        >
          <FirstRow cardText='DAO Snapshot Voting' Image={Dao} />
        </Flex>
      ) : (
        <Flex flex='1' mt={10} mx={60}>
          <ThirdRow Image={APY} />
        </Flex>
      )}
      <Flex
        display={isMobile ? "none" : undefined}
        flex='1'
        justifyContent='space-between'
        my={10}
        mx={startTab && endTab ? 10 : 60}
      >
        <FourthRow
          price={genePrice}
          cardText='GENE'
          Image={GENE}
          gene={true}
        />
        <FourthRow
          price={gnomePrice}
          cardText='GNOME'
          Image={GNOME}
          gene={false}
        />
      </Flex>
      <Flex
        flex='1'
        justifyContent='space-between'
        display={isMobile ? undefined : "none"}
        mt={10}
        mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
      >
        <SecondRow cardText='Liquidity Provider Incentives' Image={Liquidity} />
      </Flex>
      <Flex
        flex='1'
        justifyContent='space-between'
        display={isMobile ? undefined : "none"}
        mt={10}
        mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
      >
        <SecondRow cardText='GenomesDAO Blog' Image={Blog} />
      </Flex>
      <Flex
        flex='1'
        justifyContent='space-between'
        display={isMobile ? undefined : "none"}
        mt={10}
        mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
      >
        <ThirdRow Image={APY} />
      </Flex>

      <Flex
        flex='1'
        justifyContent='space-between'
        display={isMobile ? undefined : "none"}
        mt={20}
        mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
      >
        <FourthRow
          price={genePrice}
          cardText='GENE'
          Image={GENE}
          gene={true}
        />
      </Flex>

      <Flex
        flex='1'
        justifyContent='space-between'
        display={isMobile ? undefined : "none"}
        mt={10}
        mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
      >
        <FourthRow
          price={gnomePrice}
          cardText='GNOME'
          Image={GNOME}
          gene={false}
        />
      </Flex>

      <Flex mt={20} justifyContent='center'>
        <Img src={GENEGNOMEART} />
      </Flex>
      <Flex mb={5} justifyContent='center'>
        <Text fontSize={isMobile ? "12px" : "14px"}>
          *Live price data supplied by api.coingecko.com
        </Text>
      </Flex>
      <Flex mb={5} alignItems='center' justifyContent='center'>
        <Img mr={2} src={COPYRIGHT} />
        <Text fontSize={isMobile ? "10px" : "12px"}>
          All Rights Reserved GenomesDAO
        </Text>
      </Flex>
    </Box>
  );
};

export default Home;
