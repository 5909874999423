export interface Token {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
  formatDecimals: number;
  icon?: string;
  iconWidth?: number;
  iconHeight?: number;
}

export const WETH: Token = {
  symbol: 'WETH',
  name: 'Ethereum',
  address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // mainnet
  // address: '0xc778417e063141139fce010982780140aa0cd5ab', // ropsten testnet
  decimals: 18,
  formatDecimals: 3,
};

export const GENE: Token = {
  symbol: 'GENE',
  name: 'GENE',
  address: '0x21413c119b0C11C5d96aE1bD328917bC5C8ED67E', // mainnet
  // address: '0xE54804cAEbb174492730Cb4FcA32556668305B70', // ropsten testnet
  decimals: 18,
  formatDecimals: 3,
};

export const GNOME: Token = {
  symbol: 'GNOME',
  name: 'GNOME',
  address: '0xE58Eb0Bb13a71d7B95c4C3cBE6Cb3DBb08f9cBFB', // mainnet
  // address: '0x7642114F122d3fCB0B09AD325e631F1A735643A5', // ropsten testnet
  decimals: 18,
  formatDecimals: 3,
};

export const LP_GENE_ETH: Token = {
  symbol: 'LPGENEETH',
  name: 'Sushiswap LP Token for GENE/ETH',
  address: '0x9911b111860bd8A611C2A6654Ff38208208055D4', // mainnet
  // address: '0xf159eabcd7a08aab912dee3f6157278fae7de7d1', // ropsten testnet
  decimals: 18,
  formatDecimals: 6,
};

export const LP_GNOME_ETH: Token = {
  symbol: 'LPGNOMEETH',
  name: 'Sushiswap LP Token for GNOME/ETH',
  address: '0xEC51614e1306488033Bd9128d7887CBC6166C5fe', // mainnet
  // address: '0x36fbaa8e1c49c129060b90f719df2ee99ef6ffe1', // ropsten testnet
  decimals: 18,
  formatDecimals: 6,
};

export const LP_GENE_WETH_POLYGON: Token = {
  symbol: 'LPGENEWETH',
  name: 'Sushiswap LP Token for GENE/WETH',
  address: '0x3d4219987fBb25C3DcF73FbD9AA85FbE3C7411D9', // polygon
  decimals: 18,
  formatDecimals: 6,
};

export const LP_GNOME_WETH_POLYGON: Token = {
  symbol: 'LPGNOMEWETH',
  name: 'Sushiswap LP Token for GNOME/WETH',
  address: '0xc1214b61965594b3e08Ea4950747d5A077Cd1886', // polygon
  decimals: 18,
  formatDecimals: 6,
};

export const SUPPORTED_STABLE_COINS_POLYGON: Token[] = [
  {
    symbol: 'mUSD',
    name: 'mUSD',
    address: '0xE840B73E5287865EEc17d250bFb1536704B43B21', // polygon
    // address: '0xE840B73E5287865EEc17d250bFb1536704B43B21', // rinkeby
    decimals: 18,
    formatDecimals: 6,
    icon: 'musd.png',
    iconWidth: 25,
    iconHeight: 25,
  },
  {
    symbol: 'DAI',
    name: 'DAI',
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', // polygon
    // address: '0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea', // rinkeby
    decimals: 18,
    formatDecimals: 6,
    icon: 'dai.png',
    iconWidth: 25,
    iconHeight: 21,
  },
  {
    symbol: 'USDC',
    name: 'USDC',
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // polygon
    // address: '0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b', // rinkeby
    decimals: 6,
    formatDecimals: 6,
    icon: 'usdc.png',
    iconWidth: 25,
    iconHeight: 25,
  },
  {
    symbol: 'USDT',
    name: 'USDT',
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // polygon
    // address: '0xd9ba894e0097f8cc2bbc9d24d308b98e36dc6d02', // rinkeby
    decimals: 6,
    formatDecimals: 6,
    icon: 'usdt.png',
    iconWidth: 25,
    iconHeight: 22,
  },
  // {
  //   symbol: 'MIM',
  //   name: 'miMATIC',
  //   address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1', // polygon
  //   // address: '0xd9ba894e0097f8cc2bbc9d24d308b98e36dc6d02', // rinkeby
  //   decimals: 18,
  //   formatDecimals: 6,
  //   icon: 'mim.png',
  // },
  {
    symbol: 'FRAX',
    name: 'FRAX',
    address: '0x45c32fa6df82ead1e2ef74d17b76547eddfaff89', // polygon
    // address: '0xd9ba894e0097f8cc2bbc9d24d308b98e36dc6d02', // rinkeby
    decimals: 18,
    formatDecimals: 6,
    icon: 'frax.png',
    iconWidth: 25,
    iconHeight: 24,
  },
];

export const VEGNOME: Token = {
  symbol: 'VEGNOME',
  name: 'VEGNOME',
  address: '0x13c3b73f24E30B364fA7A5eDff4a752f7771696e', // polygon
  // address: '0x587EBE42d472e410e18708Ecb4fEC01532258cA6', // ropsten testnet
  decimals: 18,
  formatDecimals: 3,
};

export const APY_BOOST_UP_STABLE_COINS = 1000; // veGNOME
export const APY_MAX_BOOST_RATE_LP = 50;

export const REWARD_TOKENS_POLYGON: Token[] = [
  {
    symbol: 'GENE',
    name: 'GENE',
    address: '0x34667ED7C36cBBbF2d5d5c5c8d6Eb76a094EDb9F', // polygon
    // address: '0x1245398cE910e33114E7C17Ff874c1B81c24C1B8', // rinkeby
    decimals: 18,
    formatDecimals: 6,
  },
  {
    symbol: 'GNOME',
    name: 'GNOME',
    address: '0x6E8a8726639d12935b3219892155520bdC57366B', // polygon
    // address: '0xEfEaC524DB03843BaF614886707309B7527812c4', // rinkeby
    decimals: 18,
    formatDecimals: 6,
  },
];

export const STABLE_APY = 10;
export const STABLE_MAX_APY_BOOSTED = 10;

export const TokenList = [GENE, GNOME, LP_GENE_ETH, LP_GNOME_ETH];

export function getTokenBySymbol(symbol: string): Token {
  const token = TokenList.find(
    (t) => t.symbol.toLowerCase() === symbol.toLowerCase()
  );
  if (!token)
    throw Error(
      `Unknown token ${symbol}. Allowed tokens are ${TokenList.map(
        (t) => t.symbol
      ).join()}`
    );
  return token;
}

export function getTokenByAddress(address: string): Token {
  const token = TokenList.find(
    (t) => t.address.toLowerCase() === address.toLowerCase()
  );
  if (!token)
    throw Error(
      `Unknown token ${address}. Allowed tokens are ${TokenList.map(
        (t) => t.address
      ).join()}`
    );
  return token;
}

export const LP_GENE_ETH_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_LP_GENE_ETH_STAKING_CONTRACT_ADDRESS ||
  '0x7566f600d85c1d4E8aDBD7Ec320CF0De90c13199'; // mainnet
// '0x3EEEe56b4C7438954701f57d97467f8ad273688e'; // ropsten testnet

export const LP_GNOME_ETH_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_LP_GENE_ETH_STAKING_CONTRACT_ADDRESS ||
  '0xDF1c7e435Ee88803836bEb6f31CB2BC46B14bD5f'; // mainnet
// '0x9EdE80513e02a1882aCCFA9d841060067AFF3B9C'; // ropsten testnet

export const LP_GENE_WETH_STAKING_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_LP_GENE_WETH_STAKING_POLYGON_CONTRACT_ADDRESS ||
  '0x28fc73e9d9f158e7dc57a4e81aa0175d6847f714'; // polygon

export const LP_GNOME_WETH_STAKING_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_LP_GNOME_WETH_STAKING_POLYGON_CONTRACT_ADDRESS ||
  '0x3606cFa43f53098BC00b3FCFF3A333F6947F3c92'; // polygon

export const STABLE_COINS_GENE_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_STABLE_COINS_GENE_STAKING_CONTRACT_ADDRESS ||
  '0x99093956d058596C042b283462da5dd89Cc3C7A5'; // polygon
// '0x80E4cc2753c8deF7c696BE69f4a927b7eCC822F4'; // rinkeby testnet

export const STABLE_COINS_GNOME_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_STABLE_COINS_GENE_STAKING_CONTRACT_ADDRESS ||
  '0x374615CB8cF32360eb592f3f35163Ed4C2fDcc7A'; // polygon
// '0x6F9991f4fc8F1F1dC010bf13574adbFDeff718c5'; // rinkeby testnet

export const CHAINLINK_WETH =
  process.env.REACT_APP_CHAINLINK_WETH ||
  '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419';

// export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
export const API_URL =
  process.env.REACT_APP_API_URL || 'https://staking-poly-genomes.com';
// process.env.REACT_APP_API_URL || 'http://localhost:8080';

// export const AVAILABLE_CHAINS = [1, 4];
export const MATIC_GENE: Token = {
  symbol: 'GENE',
  name: 'GENE',
  address: '0x34667ED7C36cBBbF2d5d5c5c8d6Eb76a094EDb9F', // polygon
  // address: '0x1245398cE910e33114E7C17Ff874c1B81c24C1B8', // rinkeby
  decimals: 18,
  formatDecimals: 6,
};
export const MATIC_GNOME: Token = {
  symbol: 'GNOME',
  name: 'GNOME',
  address: '0x6E8a8726639d12935b3219892155520bdC57366B', // polygon
  // address: '0xEfEaC524DB03843BaF614886707309B7527812c4', // rinkeby
  decimals: 18,
  formatDecimals: 6,
};
