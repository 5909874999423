import { Flex, Text, Img, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";

interface CardProps {
  Image: string;
}

const ThirdRow = ({ Image }: CardProps) => {
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [hover, setHover] = useState(false);
  const handleSetState = (state: boolean) => {
    setHover(state);
  };
  return (
    <Flex
      p={8}
      onClick={() => window.open("https://staking.genomes.io/")}
      cursor='pointer'
      onMouseEnter={() => handleSetState(true)}
      onMouseLeave={() => handleSetState(false)}
      bgColor={!hover ? "#590AD1" : "#35067C"}
      borderRadius='15px'
      flex={startTab && endTab ? "0.49" : isMobile ? "1" : "1"}
      border='1px'
    >
      <Flex w='100%' justifyContent='space-between'>
        <Flex justifyContent='flex-end' flexDirection='column'>
          <Text
            fontSize={startTab && endTab ? "20px" : isMobile ? "20px" : "24px"}
            color='#FFFFFF'
            fontFamily='roman'
          >
            Earn high <b>APY’s </b>
            <span style={{ color: "#FF98EA" }}>(up to 20%) </span>
          </Text>
          <Text
            fontSize={startTab && endTab ? "12px" : isMobile ? "12px" : "18px"}
            color='#FFFFFF'
            fontFamily='roman'
          >
            on stablecoins at{" "}
            <a
              href='https://staking.genomes.io'
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            >
              staking.genomes.io
            </a>
          </Text>
        </Flex>
        <Flex>
          <Img w={startTab && endTab ? "70px" : "90px"} src={Image} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ThirdRow;
