import { getChainlinkPriceFeed } from './web3';
import { ethers } from 'ethers';
import { CHAINLINK_WETH } from '../constants/tokens';

/**
 * Returns ETH/USDC value in USD
 */

export const viewWeb3 = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_WEB3_GENOMES ||
    'https://eth-mainnet.alchemyapi.io/v2/LnUIqELT9Uv6Fdr01NQQGDBR4YPhU02X'
);

export const viewWeb3onPoly = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_WEB3_GENOMES_POLYGON ||
    'https://polygon-mainnet.g.alchemy.com/v2/V8FIYAh3-ZiD4GS_29v6byUsVDadOCus'
);
const wethOracle = getChainlinkPriceFeed(CHAINLINK_WETH, viewWeb3);
export async function getCurrentCoinValue() {
  try {
    const wethRound = await wethOracle.latestRoundData.call();
    const wethValue = ethers.utils.formatUnits(wethRound[1].toString(), 8);
    return wethValue;
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export const OS = () => {
  return getOS();
};
