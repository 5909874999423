import { useState } from "react";
import { Flex, Text, Img, useMediaQuery } from "@chakra-ui/react";
import Logo from "../../assets/genomesdaologo.png";
import Discord from "../../assets/discord.svg";
import DiscordHover from "../../assets/discordhover.svg";

const Navbar = () => {
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [hover, setHover] = useState(false);
  const handleSetState = (state: boolean) => {
    setHover(state);
  };
  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      py={10}
      mx={startTab && endTab ? 10 : isMobile ? 5 : 60}
    >
      <Img w={isMobile ? "120px" : ""} src={Logo} />
      <Flex
        cursor='pointer'
        onClick={() => window.open("https://www.discord.gg/genomes")}
        px={4}
        py={isMobile ? 2 : 3}
        color={!hover ? "#6256EC" : "#FFFFFF"}
        bgColor={!hover ? "#FFFFFF" : "#3B00E1"}
        borderRadius='30px'
        border='1px'
        borderColor='#6256EC'
        alignItems='center'
        onMouseEnter={() => handleSetState(true)}
        onMouseLeave={() => handleSetState(false)}
      >
        <Img
          mr={2}
          w={isMobile ? "14px" : "24px"}
          h={isMobile ? "14px" : "24px"}
          src={!hover ? Discord : DiscordHover}
        />
        <Text fontSize={isMobile ? "11px" : ""} fontFamily='roman'>
          {isMobile ? "Join" : "Join the community"}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Navbar;
