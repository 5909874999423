import { ethers } from 'ethers';
import { getAddress } from '@ethersproject/address';
import { Zoom } from 'zoom-next';

import {
  LP_GENE_ETH,
  LP_GNOME_ETH,
  CHAINLINK_WETH,
} from '../constants/tokens';

import LP_GENE_ETH_ABI from '../contracts/GeneETH.json';
import LP_GNOME_ETH_ABI from '../contracts/GnomeETH.json';
import CHAINLINK_ABI from '../contracts/chainlinkAggregator.json';

interface LibOptions {
  networkId: number;
  provider: any;
  zoom: boolean;
  zoom_debug?: boolean;
  debug?: boolean;
}

export default class DataStore {
  public debug: boolean = false;
  public zoom_enabled: boolean = false;
  public zoom_debug: boolean = false;
  public zoom_library_instance: any;
  public zoom_contract: any;
  public zoom_address: string = '';
  public zoom_addresses: {[key: number]: string} = {
    1: '0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea', // mainnet
    3: '0x0a68e5B770D7C8702C92359645e8561E28c8B1B0', // ropsten
    4: '0x874cfe2e846cd17187db3ac9b3cd9e47db957b28', // rinkeby
  };
  public zoom_abi = [
    {
      inputs: [{ internalType: 'bytes', name: 'inputData', type: 'bytes' }],
      name: 'combine',
      outputs: [
        { internalType: 'bytes', name: '', type: 'bytes' },
        { internalType: 'bytes', name: '', type: 'bytes' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  ];
  public provider: any = false;
  public initialised: boolean = false;

  public data: any;
  public debugLogs: any[] = [];
  public networkId = 0;

  public CHAINLINK_WETH_PRICE_CONTRACT: any;

  public LP_GNOME_ETH_STAKING_CONTRACT: any;
  public LP_GENE_ETH_STAKING_CONTRACT: any;
  public LP_GENE_ETH_CONTRACT: any;
  public LP_GNOME_ETH_CONTRACT: any;

  constructor(options: LibOptions) {
    this.debugLog('TraitsLib __constructor');

    this.require(
      typeof options.networkId === 'undefined',
      'options.networkId is required'
    );
    this.require(
      typeof options.provider === 'undefined',
      'options.provider is required'
    );

    if (options.zoom) {
      if (typeof this.zoom_addresses[options.networkId] === 'undefined') {
        throw new Error('Zoom contract does not exist on provided network');
      } else {
        this.zoom_enabled = true;

        this.zoom_debug = false;
        if (typeof options.zoom_debug !== 'undefined') {
          this.zoom_debug = true;
        }

        this.zoom_address = this.zoom_addresses[options.networkId];
        this.zoom_library_instance = new Zoom();
      }
    }

    if (options.debug === true) {
      this.debug = true;
    }

    this.provider = options.provider;
    this.networkId = options.networkId;

    this.debugLog('options.networkId     ', options.networkId);
    this.debugLog('this.zoom_address     ', this.zoom_address);
  }

  async init() {
    this.debugLog('TraitsLib init');

    this.zoom_contract = new ethers.Contract(
      this.zoom_address,
      this.zoom_abi,
      this.provider
    );

    this.CHAINLINK_WETH_PRICE_CONTRACT = new ethers.Contract(
      CHAINLINK_WETH,
      CHAINLINK_ABI,
      this.provider
    );
    this.LP_GENE_ETH_CONTRACT = new ethers.Contract(
      LP_GENE_ETH.address,
      LP_GENE_ETH_ABI,
      this.provider
    );
    this.LP_GNOME_ETH_CONTRACT = new ethers.Contract(
      LP_GNOME_ETH.address,
      LP_GNOME_ETH_ABI,
      this.provider
    );

    this.initialised = true;
  }

  async loadData(wallet_address: string) {
    wallet_address = getAddress(wallet_address);

    if (this.zoom_enabled) {
      const ZoomLibraryInstance = this.zoom_library_instance;

      let callNum = 0;

      // ---------------------------- PREPARE CALLS START ----------------------------

      const chainlink_WETH_PRICE_identifier = ZoomLibraryInstance.addCall(
        this.CHAINLINK_WETH_PRICE_CONTRACT,
        ['latestRoundData', []],
        'latestRoundData() returns (uint80, int256, uint256, uint256, uint80)'
      );
      callNum++;

      // LP_GENE_ETH_CONTRACT
      const LP_GENE_balanceOf_identifier = ZoomLibraryInstance.addCall(
        this.LP_GENE_ETH_CONTRACT,
        ['balanceOf', [wallet_address]],
        'balanceOf(address) returns (uint256)'
      );
      callNum++;

      const LP_GENE_token0_identifier = ZoomLibraryInstance.addCall(
        this.LP_GENE_ETH_CONTRACT,
        ['token0', []],
        'token0() returns (address)'
      );
      callNum++;

      const LP_GENE_token1_identifier = ZoomLibraryInstance.addCall(
        this.LP_GENE_ETH_CONTRACT,
        ['token1', []],
        'token1() returns (address)'
      );
      callNum++;

      const LP_GENE_reserves_identifier = ZoomLibraryInstance.addCall(
        this.LP_GENE_ETH_CONTRACT,
        ['getReserves', []],
        'getReserves() returns (uint112, uint112, uint32)'
      );
      callNum++;

      const LP_GENE_totalSupply_identifier = ZoomLibraryInstance.addCall(
        this.LP_GENE_ETH_CONTRACT,
        ['totalSupply', []],
        'totalSupply() returns (uint256)'
      );
      callNum++;

      // LP_GNOME_ETH_CONTRACT
      const LP_GNOME_balanceOf_identifier = ZoomLibraryInstance.addCall(
        this.LP_GNOME_ETH_CONTRACT,
        ['balanceOf', [wallet_address]],
        'balanceOf(address) returns (uint256)'
      );
      callNum++;

      const LP_GNOME_token0_identifier = ZoomLibraryInstance.addCall(
        this.LP_GNOME_ETH_CONTRACT,
        ['token0', []],
        'token0() returns (address)'
      );
      callNum++;

      const LP_GNOME_token1_identifier = ZoomLibraryInstance.addCall(
        this.LP_GNOME_ETH_CONTRACT,
        ['token1', []],
        'token1() returns (address)'
      );
      callNum++;

      const LP_GNOME_reserves_identifier = ZoomLibraryInstance.addCall(
        this.LP_GNOME_ETH_CONTRACT,
        ['getReserves', []],
        'getReserves() returns (uint112, uint112, uint32)'
      );
      callNum++;

      const LP_GNOME_totalSupply_identifier = ZoomLibraryInstance.addCall(
        this.LP_GNOME_ETH_CONTRACT,
        ['totalSupply', []],
        'totalSupply() returns (uint256)'
      );
      callNum++;

      // ----------------------------- PREPARE CALLS END -----------------------------

      // Prepare the binary call
      const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
      const loadStart = Date.now();
      if (this.zoom_debug) {
        // const hexBinary = ZoomQueryBinary.toString("hex");
        // const header = hexBinary.substr(0, 12 );
        // console.log( "ZoomQueryBinary:  HEADER:", header );
        // for(let i = 0; i < callNum; i++) {
        //     this.debugZoom(ZoomLibraryInstance.binary[i].toString("hex"));
        // }

        this.debugLog('======== ZOOM CALL START ============');
        this.debugLog('calls:', callNum);
      }

      const combinedResult = await this.zoom_contract.combine(ZoomQueryBinary);

      const loadTime = (Date.now() - loadStart) / 1000;
      if (this.zoom_debug) {
        this.debugLog('load time:', loadTime, 'seconds');
        this.debugLog('======== ZOOM CALL END ==============');
        // this.debugLog( "combinedResult", combinedResult.toString("hex") );
      }
      ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

      const chainlink_WETH = {
        latestRoundData: ZoomLibraryInstance.decodeCall(
          chainlink_WETH_PRICE_identifier
        ),
      };

      const LP_GENE = {
        balanceOf: ZoomLibraryInstance.decodeCall(LP_GENE_balanceOf_identifier),
        token0: ZoomLibraryInstance.decodeCall(LP_GENE_token0_identifier),
        token1: ZoomLibraryInstance.decodeCall(LP_GENE_token1_identifier),
        reserves: ZoomLibraryInstance.decodeCall(LP_GENE_reserves_identifier),
        totalSupply: ZoomLibraryInstance.decodeCall(
          LP_GENE_totalSupply_identifier
        ),
      };

      const LP_GNOME = {
        balanceOf: ZoomLibraryInstance.decodeCall(
          LP_GNOME_balanceOf_identifier
        ),
        token0: ZoomLibraryInstance.decodeCall(LP_GNOME_token0_identifier),
        token1: ZoomLibraryInstance.decodeCall(LP_GNOME_token1_identifier),
        reserves: ZoomLibraryInstance.decodeCall(LP_GNOME_reserves_identifier),
        totalSupply: ZoomLibraryInstance.decodeCall(
          LP_GNOME_totalSupply_identifier
        ),
      };

      this.data = {
        networkId: this.networkId,
        chainlink_WETH: chainlink_WETH,
        LP_GENE: LP_GENE,
        LP_GNOME: LP_GNOME,
        stats: {
          time: loadTime,
          calls: callNum,
        },
      };

      return this.data;
    } else {
      this.notImplemented('loadDefaults non zoom not implemented');
    }
  }

  getData() {
    return this.data;
  }

  require(isTrue: boolean, message: string) {
    if (isTrue) {
      throw new Error(message);
    }
  }

  notImplemented(message: string) {
    throw new Error(message);
  }

  debugZoom(text: string) {
    this.debugLog('ZoomQueryBinary:  CALL:  ', text);
  }

  debugLog(...args: any) {
    this.debugLogs.push(args.join(' '));
    if (this.debug) {
      console.log('TraitsLibrary:', ...args);
    }
  }
}
