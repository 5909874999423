import { Flex, Text, Img, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";

interface CardProps {
  cardText: string;

  Image: string;
}

const SecondRow = ({ cardText, Image }: CardProps) => {
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [hover, setHover] = useState(false);
  const handleSetState = (state: boolean) => {
    setHover(state);
  };
  return (
    <Flex
      p={8}
      cursor='pointer'
      onMouseEnter={() => handleSetState(true)}
      onMouseLeave={() => handleSetState(false)}
      bgColor={!hover ? "#590AD1" : "#35067C"}
      borderRadius='15px'
      flex={isMobile ? "1" : "0.49"}
      border='1px'
      onClick={() =>
        window.open(
          cardText === "Liquidity Provider Incentives"
            ? "https://vote.genomes.io/"
            : cardText === "GenomesDAO Blog"
            ? "https://genomesdao.medium.com/"
            : ""
        )
      }
    >
      <Flex w='100%' justifyContent='space-between'>
        <Flex justifyContent='flex-end' flexDirection='column'>
          <Text fontSize='20px' color='#FFFFFF' fontFamily='roman'>
            {cardText}
          </Text>
        </Flex>
        <Flex>
          <Img w='70px' src={Image} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SecondRow;
