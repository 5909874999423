import { Flex, Text, Img, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";

interface CardProps {
  cardText: string;
  Image: string;
  gene: boolean;
  price?: string;
}

const FourthRow = ({ cardText, Image, gene, price }: CardProps) => {
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [hover, setHover] = useState(false);
  const handleSetState = (state: boolean) => {
    setHover(state);
  };
  return (
    <Flex
      p={isMobile ? 6 : 8}
      bgColor='#35067C'
      borderRadius='15px'
      flex={isMobile ? "1" : "0.49"}
      border='1px'
    >
      <Flex
        w='100%'
        flexDirection={
          startTab && endTab ? "column" : isMobile ? "column" : undefined
        }
        alignItems='center'
        justifyContent='space-between'
      >
        <Flex alignItems='center' color='#ffffff'>
          <Img
            w={startTab && endTab ? "70px" : isMobile ? "70px" : "80px"}
            mr={5}
            src={Image}
          />
          <Flex flexDirection='column'>
            <Text
              fontSize={
                startTab && endTab ? "20px" : isMobile ? "20px" : "35px"
              }
              fontFamily='helvetica-bold'
            >
              {`$ `}{price}
            </Text>
            <Text fontSize='12px' fontStyle='italic'>
              Live <b>{cardText}</b> Price
            </Text>
          </Flex>
        </Flex>
        <a
          href={
            cardText === "GENE"
              ? "https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x34667ED7C36cBBbF2d5d5c5c8d6Eb76a094EDb9F"
              : "https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x6E8a8726639d12935b3219892155520bdC57366B"
          }
        >
          <Flex
            onMouseEnter={() => handleSetState(true)}
            onMouseLeave={() => handleSetState(false)}
            borderRadius='30px'
            cursor='pointer'
            bgColor={!hover ? "#ffffff" : "#6256EC"}
            color={!hover ? "#35067C" : "#ffffff"}
            py={3}
            px={3}
            mt={startTab && endTab ? 5 : isMobile ? 5 : undefined}
          >
            <Text fontSize={isMobile ? "12px" : "14px"}>
              Purchase Now on Sushi
            </Text>
          </Flex>
        </a>
      </Flex>
    </Flex>
  );
};

export default FourthRow;
