import * as React from "react";
import { ChakraProvider, Box, theme } from "@chakra-ui/react";
import Home from "./container/Home/Home";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box>
      <Home />
    </Box>
  </ChakraProvider>
);
