import { Flex, Img, Text, useMediaQuery, Link } from "@chakra-ui/react";

import { useState } from "react";

interface CardProps {
  cardText: string;
  subText?: string;
  Image: string;
}

const FirstRow = ({ cardText, subText, Image }: CardProps) => {
  const [startTab] = useMediaQuery("(max-width: 950px)");
  const [endTab] = useMediaQuery("(min-width: 570px)");
  const [isMobile] = useMediaQuery("(max-width: 560px)");
  const [hover, setHover] = useState(false);
  const handleSetState = (state: boolean) => {
    setHover(state);
  };

  return (
    <Flex
      onClick={() =>
        window.open(
          cardText === "GenomesDAO Docs"
            ? "https://genomes.gitbook.io/genomes.io-docs/"
            : cardText === "Join the community"
            ? "https://www.discord.gg/genomes "
            : cardText === "DAO Snapshot Voting"
            ? "https://snapshot.org/#/genomesdao.eth"
            : ""
        )
      }
      cursor='pointer'
      onMouseEnter={() => handleSetState(true)}
      onMouseLeave={() => handleSetState(false)}
      bgColor={!hover ? "#590AD1" : "#35067C"}
      flexDirection='column'
      p={8}
      borderRadius='15px'
      flex={startTab && endTab ? "0.49" : isMobile ? "1" : "0.32"}
    >
      <Flex w='100%' justifyContent='space-between'>
        <Flex justifyContent='flex-end' flexDirection='column'>
          <Text fontSize={"20px"} color='#FFFFFF' fontFamily='roman'>
            {cardText}
          </Text>
        </Flex>
        <Flex>
          <Img w={"70px"} src={Image} />
        </Flex>
      </Flex>
      <Text color='#FFFFFF' fontFamily='roman' fontSize='12px'>
        {subText}
      </Text>
    </Flex>
  );
};

export default FirstRow;
